import React, { Component } from "react";
import * as TemplateCreater from "survey-creator";
import * as TemplateModal from "survey-knockout";
import "survey-creator/survey-creator.css";
import "./TemplateBuilder.scss";
import "jquery-ui/themes/base/all.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import "pretty-checkbox/dist/pretty-checkbox.css";
import * as widgets from "surveyjs-widgets";
import Axios from "utility/Axios";
import "lib/survey-widget/ThumbsWidget";
import "lib/survey-widget/SmilyWidget";
import "lib/survey-widget/CSATWidget";
import "lib/survey-widget/NPSWidget";
import "lib/survey-widget/PersonalInfoText";
import { QUESTION_PROPERTY_VISIBILITY, CUSTOM_LANGUAGES } from "constants/SurveyConstants";
import { toast } from 'react-toastify';
import configURL from 'config/config';
import EziAlert from 'components/Alert';
import TemplateBuilderTab from './TemplateBuilderTab';
import * as CONFIG from "./Constants";
import EziLoader from "components/EziLoader";
import ThemeMappingModal from "../components/ThemeMappingModal";
import QuestionMapping from "./QuestionMapping";
import { uniqueGenerator, surveyQuestionModifier, canSaveBuilderQuestions, mapParentChild, doMarkdown, CkEditor_ModalEditor } from "utility/helper";
import { connect } from "react-redux";
import * as AppActions from "store/actions";

/**Custom  widgets**/
widgets.prettycheckbox(TemplateModal);
widgets.select2(TemplateModal, $);
widgets.inputmask(TemplateModal);
widgets.jquerybarrating(TemplateModal, $);
widgets.jqueryuidatepicker(TemplateModal, $);
widgets.select2tagbox(TemplateModal, $);
widgets.sortablejs(TemplateModal);
widgets.ckeditor(TemplateModal);
widgets.autocomplete(TemplateModal, $);
widgets.bootstrapslider(TemplateModal);
/**Custom  widgets end**/
var templatePreviewModel;

class TemplateBuilder extends Component {
	templateCreator;
	isTranTabInitialised = false
	state = {
		category_id: this.props.match.params.category_id,
		template_id: this.props.match.params.template_id,
		confirm_save: false,
		loading: false,
		canSaveQuestion: false,
		template_locales: [],
		modalShow: false,
		showQuestionMap: false
	}
	inputSearchTimer = null;

	updateInitialQuestion = (question_json) => {
		this.setState({ loading: false })
		if (question_json) {
			let canSave = canSaveBuilderQuestions(question_json)
			this.props.dispatchCanSave(canSave)
			this.templateCreator.text = JSON.stringify(question_json);
		}
	}

	getInitialData = async (cb) => {
		this.setState({ loading: true });
		let formData = new FormData();
		formData.append("category_id", this.props.match.params.category_id);
		formData.append("template_id", this.props.match.params.template_id);
		let templateData = await Axios.post(configURL.partner_get_template_builder_initals, formData)
		if (templateData.data.success !== undefined && templateData.data.success === true) {
			let locales = [...new Set(templateData.data.results.template_locales.filter(el => el !== "")), '']
			this.setState({ template_locales: locales })
			this.props.dispatchInitial({
				templateName: templateData.data.results.template_name || "",
				categoryName: templateData.data.results.category_name || "",
				templateStatus: templateData.data.results.status || "",
				publishStatus: templateData.data.results.ispublished || "",
			})
			this.updateInitialQuestion(templateData.data.results.template_json)
		}
		this.setState({ loading: false })
		cb()
	}
	componentDidMount() {
		this.props.dispatchResetTemplateData()
		for (let key in CONFIG.TOOLBAR_NAMES) {
			TemplateCreater.editorLocalization.getLocale(TemplateCreater.editorLocalization.currentLocale).ed[key] = CONFIG.TOOLBAR_NAMES[key];
		}
		/**Change Template Text */
		TemplateCreater.editorLocalization.getLocale(TemplateCreater.editorLocalization.currentLocale).ed.designer = "Template Designer";
		TemplateCreater.editorLocalization.getLocale(TemplateCreater.editorLocalization.currentLocale).ed.logic = "Template Logic";
		TemplateCreater.editorLocalization.getLocale(TemplateCreater.editorLocalization.currentLocale).ed.testSurvey = "Preview Template";
		/** Change Template Text End */
		/** CK Editor Property Add Start */
		TemplateCreater.SurveyPropertyModalEditor.registerCustomWidget("html", CkEditor_ModalEditor);
		/** CK Editor Property Add End */
		this.templateCreator = new TemplateCreater.SurveyCreator("templateCreaterElement", CONFIG.BUILDER_OPTIONS);
		let toolBoxItems = this.templateCreator.toolbox.items;
		this.templateCreator.toolbox.orderedQuestions = CONFIG.TOOLBOX_ORDERS;
		for (let key in this.templateCreator.toolbox.items) {
			if (toolBoxItems.hasOwnProperty(key)) {
				if (CONFIG.TOOLBOX_NAME.hasOwnProperty(toolBoxItems[key]['name'])) {
					this.templateCreator.toolbox.replaceItem(toolBoxItems[key]);
					toolBoxItems[key]['title'] = CONFIG.TOOLBOX_NAME[toolBoxItems[key]['name']];
					toolBoxItems[key]['tooltip'] = CONFIG.TOOLBOX_NAME[toolBoxItems[key]['name']];
				}
			}
		}
		this.templateCreator.onActiveTabChanged.add((sender, options) => {
			if (options.tabName === "translation") {
				if (!this.isTranTabInitialised) {
					sender.translation.setSelectedLocales(this.state.template_locales);
					this.isTranTabInitialised = true;
				}
			}
		});
		TemplateModal.JsonObject.metaData.addProperty("question", "id");
		TemplateModal.JsonObject.metaData.addProperty("question", "question_type");
		TemplateModal.JsonObject.metaData.addProperty("question", "parent_question_id");
		this.templateCreator.onElementDoubleClick.add(function (sender, options) {
			sender.showQuestionEditor(options.element);
		});
		this.templateCreator.onQuestionAdded.add(function (sender, options) {
			options.question.id = uniqueGenerator()
			options.question.question_type = CONFIG.QUESTION_TYPES[options.question.getType()] || options.question.getType()
		});
		TemplateModal.Serializer.addProperty("matrix", {
			name: "Types",
			title: "Types",
			choices: Object.keys(CONFIG.LIKERT_CHOICES),
		});
		this.templateCreator.saveSurveyFunc = this.saveTemplateQuestions;
		TemplateModal.Serializer.findProperty("question", "question_type").visible = false;
		TemplateModal.Serializer.findProperty("question", "parent_question_id").visible = false;
		TemplateModal.Serializer.findProperty("question", "id").visible = false;
		TemplateModal.Serializer.findProperty("question", "name").visible = false;
		TemplateModal.Serializer.findProperty("page", "name").visible = false;
		TemplateModal.Serializer.addProperty("matrix", {
			name: "Options",
			title: "Options",
			dependsOn: "Types",
			choices: function (obj) {
				var entity = !!obj ? obj.Types : null;
				if (entity != null) {
					return Object.keys(CONFIG.LIKERT_CHOICES[entity]);
				}
				else {
					return [];
				}
			}
		});
		/** -----Custom Languages--- */
		Object.keys(CUSTOM_LANGUAGES).forEach(key => {
			TemplateModal.surveyLocalization.locales[key] = CUSTOM_LANGUAGES[key].localeStrings;
			TemplateModal.surveyLocalization.localeNames[key] = CUSTOM_LANGUAGES[key].label;
		})

		TemplateModal.JsonObject.metaData.addProperty("personalinfo", { name: "isUnique:switch", default: false });
		TemplateModal.JsonObject.metaData.addProperty("question", { name: "hasParentQuestion:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("dropdown", { name: "isIdentifier:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("radiogroup", { name: "isIdentifier:switch", default: false, category: "general" })
		TemplateModal.JsonObject.metaData.addProperty("checkbox", { name: "isIdentifier:switch", default: false, category: "general" })
		TemplateModal.JsonObject.metaData.addProperty("nps_rating", { name: "isIdentifier:switch", default: false, category: "general" })
		TemplateModal.JsonObject.metaData.addProperty("rating_csat", { name: "isIdentifier:switch", default: false, category: "general" })
		TemplateModal.JsonObject.metaData.addProperty("smily", { name: "isIdentifier:switch", default: false, category: "general" })

		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("isIdentifier");
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("isUnique");
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("hasParentQuestion");
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("Types");
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("Options");

		/** Adding is overriding start*/
		TemplateModal.JsonObject.metaData.addProperty("rating_csat", { name: "isOverriding:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("nps_rating", { name: "isOverriding:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("smily", { name: "isOverriding:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("radiogroup", { name: "isOverriding:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("rating", { name: "isOverriding:switch", default: false, category: "general" });
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("isOverriding");
		/** Adding is overriding end*/

		/** Adding has calculation start*/
		TemplateModal.JsonObject.metaData.addProperty("radiogroup", { name: "hasCalculation:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("rating", { name: "hasCalculation:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("barrating", { name: "hasCalculation:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("nps_rating", { name: "hasCalculation:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("rating_csat", { name: "hasCalculation:switch", default: false, category: "general" });
		TemplateModal.JsonObject.metaData.addProperty("smily", { name: "hasCalculation:switch", default: false, category: "general" });
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("hasCalculation");
		/** Adding has calculation end*/

		TemplateModal.JsonObject.metaData.addProperty("question", { name: "addToQuestionBank:switch", category: "Question Bank" });
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("addToQuestionBank");

		TemplateModal.JsonObject.metaData.addProperty("question", {
			name: "questionScope",
			choices: [{ value: null }, { value: false, text: "Public" }, { value: true, text: "Private" }],
			dependsOn: 'addToQuestionBank',
			visibleIf: function (obj) {
				return (obj.addToQuestionBank === true);
			},
			category: "Question Bank",
			isRequired: true,
		});

		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("questionScope");
		TemplateModal.JsonObject.metaData.addProperty("question", {
			name: "category",
			// choices: ["advertising_marketing|Advertising & Marketing", "Americas", "Asia", "Europe", "Oceania"],
			choices: function (obj, choicesCallback) {
				Axios.post(configURL.industry_type).then(response => {
					let industryType = response.data;
					var res = [];
					res.push({ value: null });
					for (var i = 0; i < industryType.length; i++) {
						var item = industryType[i];
						res.push({ value: item.id, text: item.industry_names });
					}
					//return the result into Survey Creator property editor
					choicesCallback(res);
				})
			},
			dependsOn: 'addToQuestionBank',
			visibleIf: function (obj) {
				return (obj.addToQuestionBank === true);
			},
			category: "Question Bank",
			isRequired: true,
		});
		TemplateCreater.SurveyQuestionEditorDefinition.definition.question.properties.push("category");
		this.templateCreator.onPropertyValueChanging.add(function (sender, options) {
			if (options.propertyName === "Options") {
				options.obj.columns = CONFIG.LIKERT_CHOICES[options.obj.Types][options.newValue];
			}
		});
		this.templateCreator.onPageAdded.add((sender, options) => {
			let { page: { questions = [] } } = options
			questions.forEach(el => {
				el.id = uniqueGenerator()
			})
		});
		this.templateCreator.toolbarItems.push({
			id: "thememapping",
			visible: true,
			title: "Theme Mapping",
			action: () => {
				this.setState({ modalShow: true })
			}
		});
		this.templateCreator.toolbarItems.push({
			id: "questionmapping",
			visible: true,
			title: "Questions Mapping",
			action: () => {
				this.setState({ showQuestionMap: true })
			}
		});
		this.templateCreator.onCanShowProperty.add(function (sender, options) {
			if (options.obj.getType() === "rating_csat") {
				options.canShow = QUESTION_PROPERTY_VISIBILITY["rating_csat"].includes(options.property.name)
			}
			if (options.obj.getType() === "nps_rating") {
				options.canShow = QUESTION_PROPERTY_VISIBILITY["nps_rating"].includes(options.property.name)
			}
			if (options.obj.getType() === "smily") {
				options.canShow = QUESTION_PROPERTY_VISIBILITY["smily"].includes(options.property.name)
			}
			if (options.obj.getType() === "yesno") {
				options.canShow = QUESTION_PROPERTY_VISIBILITY["yesno"].includes(options.property.name)
			}
		});
		/** CK Editor Property Configuration Start */
		this.templateCreator.survey.onTextMarkdown.add(doMarkdown);
		this.templateCreator.onDesignerSurveyCreated.add(function (editor, options) {
			options.survey.onTextMarkdown.add(doMarkdown);
		});
		this.templateCreator.onTestSurveyCreated.add(function (editor, options) {
			options.survey.onTextMarkdown.add(doMarkdown);
		});
		/** CK Editor Property Configuration End */
		this.templateCreator.hideAdvancedSettings = true;
		this.templateCreator.toolbarItems().reverse();
		this.templateCreator.toolbox.items.forEach((item, i) => {
			let toolNode = item;
			let nodeIconName = toolNode.iconName;
			toolNode.iconName = `${nodeIconName}_custom`;
			this.templateCreator.toolbox.replaceItem(toolNode);
		});
		TemplateCreater.StylesManager.ThemeColors["default"] = CONFIG.BUILDER_THEME
		TemplateCreater.StylesManager.applyTheme("default");
		if (this.props.location.state && this.props.location.state.canModify === false) {
			this.templateCreator.readOnly = true
		}
		this.getInitialData(() => {
			this.setState({ canSaveQuestion: true })
		})
	}

	saveTemplateQuestions = () => {
		let question_json = JSON.parse(this.templateCreator.text);
		let questionsData = surveyQuestionModifier(question_json)
		let canSave = canSaveBuilderQuestions(question_json)
		this.props.dispatchCanSave(canSave)
		if (this.state.canSaveQuestion) {
			clearTimeout(this.inputSearchTimer);
			this.inputSearchTimer = setTimeout(() => this.handleSaveBuilderData(questionsData), 500);
		}
	}

	handleSaveBuilderData = (questionsData) => {
		let locales = this.isTranTabInitialised ?
			this.templateCreator.translation.getSelectedLocales() :
			this.state.template_locales
		let formData = new FormData();
		questionsData.pages.length > 0 && questionsData.pages.forEach((item, index) => {
			if (typeof (item.elements) !== 'undefined') {
				item.elements.length > 0 && item.elements.forEach((question, index) => {
					if (question.addToQuestionBank) {
						if (typeof (question.title) !== 'undefined' && typeof (question.category) !== 'undefined' && typeof (question.questionScope) !== 'undefined') {
							//questionBankList.push(question)
							let formDataQuestion = new FormData();
							formDataQuestion.append("question_json", JSON.stringify(question));
							//formDataQuestion.append("questionbank_id", JSON.stringify(question.id));
							Axios.post(configURL.addToQuestionBank, formDataQuestion)
						}
					}
				})
			}
		})
		formData.append("template_id", this.props.match.params.template_id);
		formData.append("template_json", JSON.stringify(questionsData));
		formData.append("template_locales", JSON.stringify(locales));
		Axios.post(configURL.partner_save_template_question, formData)

	}

	saveTemplateData = (type = "save") => {
		this.setState({ confirm_save: false, loading: true })
		let formData = new FormData();
		formData.append("category_id", this.state.category_id);
		formData.append("template_id", this.state.template_id);
		formData.append("publish_type", type);
		Axios.post(configURL.partner_save_template_builder_setting, formData).then(response => {
			this.setState({ loading: false })
			if (response.data.success !== undefined && response.data.success === true) {
				this.props.dispatchResetTemplateData()
				this.props.history.push("/template-dashboard")
				toast.success(response.data.message);
			} else {
				toast.warn(response.data.message);
			}
		})
	}

	changeTemplateLocale = (lang) => {
		this.props.dispatchSelectedLanguage(lang.name)
		templatePreviewModel.locale = lang.id
	}

	previewTemplate = () => {
		let locales = this.isTranTabInitialised ? this.templateCreator.translation.getSelectedLocales() : this.state.template_locales
		let usedLocales = []
		usedLocales = locales.filter(item => item !== "").map(el => {
			return {
				id: el,
				name: TemplateModal.surveyLocalization.localeNames[el] || "Default"
			}
		})
		usedLocales.push({ id: "", name: "English" })
		this.props.dispatchLanguages(usedLocales)
		let question_json = JSON.parse(this.templateCreator.text);
		let questionsData = surveyQuestionModifier(question_json)
		templatePreviewModel = new TemplateModal.Model(JSON.stringify(questionsData));
		this.props.dispatchSelectedLanguage(null)
		templatePreviewModel.render("templatePreviewElement");
	}

	handleMapQuestion = async (mappings = {}) => {
		try {
			this.setState({ loading: true });
			let mappedJson = mapParentChild(JSON.parse(this.templateCreator.text), mappings)
			this.templateCreator.text = JSON.stringify(mappedJson)
			this.handleSaveBuilderData(mappedJson)
			let formData = new FormData()
			formData.append('template_id', this.props.match.params.template_id)
			formData.append('mappings_data', JSON.stringify(mappings))
			const saveMapping = await Axios.post(configURL.saveQuestionMapData, formData)
			if (saveMapping.data.success) {
				toast.success(saveMapping.data.message || "Mappings Saved.")
			} else {
				toast.warn(saveMapping.data.message || "Something is not right here..")
			}
			this.setState({ loading: false });
		} catch (error) {
			this.setState({ loading: false });
			toast.warn("Something is not right here..")
			console.error(error);
		}
	}

	render() {
		return (
			<React.Fragment>
				<EziAlert show={this.state.confirm_save}
					alerttext="Are you sure you want to save ?"
					confirmtext="Save Only"
					confirm={() => this.saveTemplateData("save")}
					onConfirmTwo={() => this.saveTemplateData("save_publish")}
					showBtnTwo={true}
					onhide={() => this.setState({ confirm_save: false })}
					confirmTwotext="Save & Publish"
					showClose
				/>
				<section className="Page-TemplateCreator">
					<div className="survey-creator-header">
						<div className="survey-creator-header-left">
							<label className="survey-theme-name">{this.props.categoryName || ""}</label>
							<label className="survey-template-name">{this.props.templateName || ""}</label>
						</div>
						<div className="survey-creator-header-right">
							<button type="button" className="template_back" onClick={() => {
								this.props.dispatchResetTemplateData()
								this.props.history.push("/template-dashboard")
							}}> Back to Dashboard </button>
							<label className={`survey-status-name ${this.props.templateStatus}`}>{(this.props.templateStatus) ? this.props.templateStatus : "New"}  {(this.props.publishStatus === true) ? " & Publish" : " & Unpublished"}</label>
						</div>
					</div>
					{this.state.modalShow &&
						<ThemeMappingModal
							id={this.state.template_id}
							questionType="template"
							show={this.state.modalShow}
							onHide={() => this.setState({ modalShow: false })}
						/>}
					<QuestionMapping
						onHide={() => this.setState({ showQuestionMap: false })}
						mapQuestions={this.handleMapQuestion}
						isShown={this.state.showQuestionMap}
					/>
					<TemplateBuilderTab
						changeLocale={this.changeTemplateLocale}
						saveTemplate={() => this.setState({ confirm_save: true })}
						preview={this.previewTemplate}
					/>
				</section>
				{this.state.loading && <EziLoader />}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		templateName: state.template.templateName,
		categoryName: state.template.categoryName,
		templateStatus: state.template.templateStatus,
		publishStatus: state.template.publishStatus
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatchInitial: (data) => dispatch(AppActions.setTemplateInitials(data)),
		dispatchResetTemplateData: () => dispatch(AppActions.resetTemplateState()),
		dispatchCanSave: (flag) => dispatch(AppActions.launchableTemplate({ canSave: flag })),
		dispatchLanguages: (locales) => dispatch(AppActions.setTemplateLocales({ locales })),
		dispatchSelectedLanguage: (language) => dispatch(AppActions.setTemplateLocale({ language })),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateBuilder);