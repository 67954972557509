import React, { useState, useEffect, useRef, useContext } from "react";
import './QuestionBank.scss';
import { Breadcrumb } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import useOutsideClick from "hooks/useOutsideClick";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from "utility/Axios";
import configURL from 'config/config';
import AppContext from 'store/AppContext';
import SweetSearch from "components/SweetSearch";
import { toast } from 'react-toastify';

const QuestionBank = ({ history, props }) => {
    const { EziLoader, languageObj = {} } = useContext(AppContext)
    const ref = useRef();
    const [dropdownFlag, setDropdownFlag] = useState(false)
    const [category, setCategory] = useState({});
    const [categoryDs, setCategoryDs] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [tableMeta, setTableMeta] = useState({
        pagination: {},
        columns: [{}],
        uniqueKey: "id"
    });
    const per_page = 10
    let searchTimer = null
    let inputSearch = useRef(null)
    const [searchLoading, setSearchLoading] = useState(false);

    const dropdown = () => {
        setSearchTerm('');
        setDropdownFlag(!dropdownFlag);
    }

    const scopeFormatter = (cell, row) => {
        return (
            <span className={`question-status_c ${row.is_private === true ? "Private" : "Public"}`}>{row.is_private === true ? "Private" : "Public"}</span>
        );
    }

    const statusFormatter = (cell, row) => {
        return (
            <span className={`question-status_c ${row.active === true ? "Active" : "Inactive"}`}>{row.active === true ? "Active" : "Inactive"}</span>
        );
    }


    const changeCategory = (categoryObj) => {
        console.log(categoryObj)
        setDropdownFlag(!dropdownFlag);
        setCategory(categoryObj);
        setQuestionBankListing(categoryObj);
    }
    const setQuestionBankListing = (categoryObj, pageno = 1) => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("industry_id", categoryObj.id);
        formData.append("page", pageno);
        formData.append("per_page", per_page);
        Axios.post(configURL.question_bank_list, formData).then(res => {
            EziLoader.hide()
            if (res.data.success !== undefined && res.data.success) {
                setQuestions(res.data.result);
                let columnData = res.data.columns
                columnData.filter((data, index) => {
                    return (data.dataField === "is_private" && data.text === 'Scope') ? data.formatter = scopeFormatter : "";
                })

                columnData.filter((data, index) => {
                    return (data.dataField === "active" && data.text === 'Status') ? data.formatter = statusFormatter : "";
                })
                setTableMeta({
                    pagination: res.data.pagination,
                    columns: columnData,
                    uniqueKey: res.data.unique_key || "id"
                })
            } else {
                setQuestions([]);
            }
        }).catch(err => {
            console.log(err);
            EziLoader.hide()
        })
    };

    /**
     * Search Table Handler
     */
    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getQuestions();
        }, 1000);
    }
    /**
         * Get Question Data
         */
    const getQuestions = (page = 1) => {
        let formData = new FormData()
        let search = inputSearch.current.value;
        formData.append("page", page);
        formData.append("search", search);
        formData.append("language", languageObj.curLang);
        formData.append("per_page", per_page);
        formData.append("industry_id", category?.id);
        Axios.post(configURL.question_bank_list, formData).then(res => {
            EziLoader.hide()
            setSearchLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                setQuestions(res.data.result);
                let columnData = res.data.columns
                columnData.filter((data, index) => {
                    return (data.dataField === "is_private" && data.text === 'Scope') ? data.formatter = scopeFormatter : "";
                })

                columnData.filter((data, index) => {
                    return (data.dataField === "active" && data.text === 'Status') ? data.formatter = statusFormatter : "";
                })
                setTableMeta({
                    pagination: res.data.pagination,
                    columns: columnData,
                    uniqueKey: res.data.unique_key || "id"
                })
            } else {
                toast.warn(res.data.message || "Something went wrong")
                setQuestions([]);
            }
        }).catch(err => {
            EziLoader.hide()
            setSearchLoading(false);
            toast.error("Something went wrong")
        })
    }
    const searchCategory = (e) => {
        setSearchTerm(e.target.value);
    }

    useOutsideClick(ref, () => {
        dropdownFlag && setDropdownFlag(false);
    });


    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={tableMeta.columns}
            noDataIndication="Question Not Found"
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
            rowClasses="question-bank-datatable-row"
        />
    );

    const handleAllEntriesChange = (type, { page, sizePerPage }) => {
        if (type === 'pagination') {
            setQuestionBankListing(category, page);
        }
    }

    const getInitialCatgory = () => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("is_all", true);
        Axios.post(configURL.industry_type, formData).then(res => {
            EziLoader.hide()
            if (res.data && res.data.length > 0) {
                setCategoryDs(res.data);
                let categoryObj = res.data[0];
                setCategory(categoryObj);
                setQuestionBankListing(categoryObj, 1);
            }
        }).catch(err => {
            console.log(err);
            EziLoader.hide()
        })
    }

    useEffect(getInitialCatgory, []);

    useEffect(() => {
        const results = categoryDs.filter(ele => {
            return ele.industry_names.toLowerCase().includes(searchTerm.toLowerCase())
        });
        setSearchResults(results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    let categoryList = (searchTerm !== '') ? searchResults : categoryDs;
    return (
        <React.Fragment>
            <section className="Page-QuestionBank" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => history.push("/dashboard")}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => history.push("/questionbank")}>QuestionBank</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="themes-dropdown" >
                        <button type="button" onClick={dropdown} className={`themes-dropdown-btn ${category.industry_names ? "selected" : ""}`}>{(category.industry_names) ? category.industry_names : languageObj.translate('SelectCategory.1')}</button>
                        <div className={`themes-inner-dropdown ${dropdownFlag ? 'active' : ""}`} ref={ref}>
                            <div className="themes-dropdown-header">
                                <input type="text" className="themes-dropdown-search" value={searchTerm} onChange={(e) => searchCategory(e)} placeholder={languageObj.translate('Search.1')} />
                                <label>{languageObj.translate('Category.1')}</label>
                            </div>
                            <ul>
                                {

                                    categoryList.map(categoryData =>
                                        <li onClick={() => changeCategory(categoryData)} key={categoryData.id}>
                                            {categoryData.industry_names}
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    {/* <span className="themes-subtitle">Question Bank</span> */}
                </div>
                <div className="themes-content">

                    <div className="ezi-sweet-tab">
                        <div className="filter-search-wrap">
                            <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                        </div>
                        <RemotePagination
                            data={questions}
                            page={tableMeta.pagination.current_page}
                            sizePerPage={tableMeta.pagination.per_page}
                            totalSize={tableMeta.pagination.total}
                            onTableChange={handleAllEntriesChange}
                        />
                    </div>

                </div>
            </section>
        </React.Fragment>
    )
}

export default QuestionBank;